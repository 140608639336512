<template>
    <div class="main site-content site-mover" v-if="detail">
        <div class="item-wrapper">
            <section class="productImages">
                <div class="f">
                    <!-- 中间大图-->
                    <div class="lazyLoader product-images-main product-images-main-page">
                        <ul class="alternativeImages PCImages">
                            <li class="product-images-main-entry" v-for="(image, index) in detail.images" :key="index">
                                <el-image
                                    :src="image"
                                    :preview-src-list="detail.images"
                                >
                                </el-image>
                                <!--                <img :src="image">-->
                            </li>
                        </ul>
                        <!-- 小于1280px轮播-->
                        <div class="alternativeImages mobileSwipe">
                            <van-swipe class="my-swipe" :autoplay="50000" indicator-color="white">
                                <van-swipe-item v-for="(image, index) in detail.images" :key="index">
                                    <img :src="image">
                                </van-swipe-item>
                            </van-swipe>
                        </div>
                    </div>
                    <!-- 左边-->
                    <div class="product-left-column">
                        <a class="back-button share" @click="goBack">Back</a>
                        <ul class="product-images-thumbs product-images-thumbs-page">
                            <li
                                class="product-images-nav-entry"
                                :class="{ 'product-images-entry-selected': index === currentSelectedLeftThumbnailIndex ? true : false }"
                                v-for="(image, index) in detail.images" :key="index"
                                @click="selectedLeftThumbnail(index)"
                            >
                                <button>
                  <span>
                  <img :src="image">
                  </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <!--右边-->
            <div class="product-data">
                <div class="product-data-main">
                    <div class="item-top-wrapper">
                        <h1 class="item-title">
                            <span>{{ detail.name }}</span>
                            <!--              <span>Luka Shirt in Cotton</span>-->
                        </h1>
                        <div class="priceUpdater">$ {{ fenToYuan(selectedAttributePrice || detail.price) }}</div>
                    </div>
                    <section class="item-action-container">
                        <div class="sticky-select-wrapper">
                            <div class="desktop-select-color" v-if="detail.attributes[0]">
                                <div class="selectColor">
                                    <div class="selectionLabel">
                                        <span>{{ detail.attributes[0].name }}: </span>
                                        <span v-if="selectedAttribute1">{{ selectedAttribute1.value }}</span>
                                    </div>
                                    <ul class="choseColor">
                                        <li
                                            class="colorItem"
                                            :class="{ 'selected': selectedAttribute1 && selectedAttribute1.id === attribute.id }"
                                            style="padding: 3px 6px;"
                                            v-for="(attribute, index) in detail.attributes[0].values"
                                            :key="index"
                                            @click="onSelectedAttribute1(attribute)"
                                        >
                                            <span>{{ attribute.value }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="packing">
                                <div>Pack</div>
                                <div class="packing-bag">
                                    <div class="bag-items" @click="bagsNatural"
                                         :class="{'active-bag': bagChooseNatural===true}">Recycle bag
                                    </div>
                                    <div class="look-up" :style="bagChooseNatural ? '' : 'display:none'"
                                         @click="lookUp">View
                                    </div>
                                    <div class="bag-items normal" @click="bagsNormal"
                                         :class="{'active-bag': bagChooseNormal===true}">Bandbox
                                    </div>
                                    <el-image-viewer
                                        v-if="naturalImg"
                                        :on-close="closeViewer"
                                        :url-list="previewImgList"
                                    />
                                </div>
                            </div>
                            <div
                                class="size-guide-link-container"
                                v-if="detail.attributes[1]"
                            >
                                <button>{{ detail.attributes[1].name }}</button>
                            </div>
                            <div
                                class="productSelectSize restockable-sizes-select ready show-size-selector"
                            >
                                <el-select
                                    @change="onSelectedAttribute2"
                                    v-if="detail.attributes[1]"
                                    v-model="selectedAttribute2"
                                    placeholder="Please choose"
                                >
                                    <el-option
                                        v-for="item in detail.attributes[1].values"
                                        :key="item.id"
                                        :label="item.value"
                                        :value="item"
                                    >
                                    </el-option>
                                </el-select>
                                <button @click="addToCart">Add to Shopping Bag</button>
                            </div>
                            <div class="mobile-sticky-select-wrapper">
                                <div
                                    class="mobile-select-color"
                                    v-if="detail.attributes[0]"
                                    :style="{ 'width': detail.attributes[1] ? '50%' : '100%' }"
                                >
                                    <button class="mobile-selectionLabel">
                                        <el-select
                                            v-model="selectedAttribute1"
                                            placeholder="Please choose"
                                            class="mobile-select"
                                        >
                                            <el-option
                                                v-for="item in detail.attributes[0].values"
                                                :key="item.id"
                                                :label="item.value"
                                                :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                    </button>
                                </div>
                                <div class="mobile-productSelectSize" v-if="detail.attributes[1]">
                                    <button class="mobile-selectionLabel right">
                                        <el-select
                                            @change="onSelectedAttribute2"
                                            class="mobile-select"
                                            v-model="selectedAttribute2"
                                            placeholder="Please choose"
                                        >
                                            <el-option
                                                v-for="item in detail.attributes[1].values"
                                                :key="item.id"
                                                :label="item.value"
                                                :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                    </button>
                                </div>
                                <button class="mobile-button" @click="addToCart">Add to Shopping Bag</button>
                            </div>
                        </div>
                    </section>
                </div>
                <!--        <div class="share">share</div>-->
                <div class="smartphone-back-button share"><a @click="goBack">Back</a></div>
            </div>
        </div>
        <div class="sticky-product-info" :class="isFixed === true ? 'isFixed' : 'unIsFixed'">
            <div class="item-top-wrapper">
                <h1 class="item-title">
                    <span>{{ detail.name }}</span>
                    <!--          <span>Luka Shirt in Cotton</span>-->
                </h1>
                <div class="priceUpdater">
                    <div class="itemPrice">$: {{ fenToYuan(selectedAttributePrice || detail.price) }}</div>
                </div>
            </div>
            <section class="item-action-container">
                <div class="sticky-select-wrapper">
                    <div class="desktop-select-color" v-if="detail.attributes[0]">
                        <div class="selectColor">
                            <div class="selectionLabel">
                                <span>{{ detail.attributes[0].name }}: </span>
                                <span v-if="selectedAttribute1">{{ selectedAttribute1.value }}</span>
                            </div>
                            <span
                                class="colorItem"
                                :style="{ 'border': selectedAttribute1 && selectedAttribute1.id === attribute.id ? '1px solid #212121' : 'none' }"
                                style="padding: 3px 6px;"
                                v-for="(attribute, index) in detail.attributes[0].values"
                                :key="index"
                                @click="onSelectedAttribute1(attribute)"
                            >
                <span>{{ attribute.value }}</span>
              </span>
                        </div>
                    </div>

                    <div
                        class="productSelectSize restockable-sizes-select ready show-size-selector"
                    >
                        <el-select
                            @change="onSelectedAttribute2"
                            v-if="detail.attributes[1]"
                            v-model="selectedAttribute2"
                            placeholder="Please choose"
                        >
                            <el-option
                                v-for="item in detail.attributes[1].values"
                                :key="item.id"
                                :label="item.value"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                        <button @click="addToCart">Add to Shopping Bag</button>
                    </div>
                </div>
            </section>
        </div>
        <div class="otherSort">
            <section class="products-grid"></section>
        </div>
        <div class="product-details">
            <div class="product-text">
                <div style="font-size: 0.8rem">Detail</div>
                <div v-html="detail.desc" class="product-desc"></div>
            </div>
        </div>
    </div>
</template>
<script>
import {addToCart, fetchProductDetail} from "../api/api";
import {f, priceTransform} from "@/utils/util";
import {EventBus} from "../utils/event-bus.js";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import previewPack from "@/assets/preview-pack.jpeg";

export default {
    components: {ElImageViewer},
    data() {
        return {
            packs: [
                {
                    id: 1,
                    name: "Recycle bag",
                    status: true
                },
                {
                    id: 2,
                    name: "Bandbox",
                    status: false
                }
            ],
            selectedPack: {
                id: 1,
                name: "Recycle bag",
                status: true
            },
            bagChooseNatural: true,
            bagChooseNormal: false,
            naturalImg: false,
            previewImgList: [previewPack],
            detail: null,
            selectedAttributePrice: null,
            selectedAttribute1: null,
            selectedAttribute2: null,
            currentSelectedLeftThumbnailIndex: 0,

            /*以下可能废弃*/
            isFixed: "",
            sizeChose: "",
            color: "pink",
        };
    },
    computed: {
        imgHeight() {
            return document.documentElement.clientHeight;
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);

        const {id} = this.$route.params;

        this.getDetail(id);
    },
    methods: {
        bagsNatural() {
            this.bagChooseNatural = true;
            this.bagChooseNormal = false;
            this.selectedPack = this.packs[0];
        },
        bagsNormal() {
            this.bagChooseNatural = false;
            this.bagChooseNormal = true;
            this.selectedPack = this.packs[1];
        },
        lookUp() {
            this.naturalImg = true;
        },
        closeViewer() {
            this.naturalImg = false;
        },
        selectedLeftThumbnail(index) {
            this.currentSelectedLeftThumbnailIndex = index;
            document.getElementsByClassName("product-images-main-entry")[index].scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center"
            });
        },
        fenToYuan(fen) {
            return priceTransform(fen);
        },
        async addToCart() {
            let sku = {};
            let stockId = null;
            if (this.selectedAttribute1) {
                sku[this.detail.attributes[0].name] = this.selectedAttribute1.value;
            }
            if (this.selectedAttribute2) {
                sku[this.detail.attributes[1].name] = this.selectedAttribute2.value;

                this.detail.stocks.map(stock => {
                    if (this.detail.attributes[0].name in stock
                        && this.detail.attributes[1].name in stock) {
                        if (stock[this.detail.attributes[0].name] === this.selectedAttribute1.value
                            && stock[this.detail.attributes[1].name] === this.selectedAttribute2.value) {
                            stockId = stock.id;
                        }
                    }
                });
            } else {
                this.detail.stocks.map(stock => {
                    if (this.detail.attributes[0].name in stock) {
                        if (stock[this.detail.attributes[0].name] === this.selectedAttribute1.value) {
                            stockId = stock.id;
                        }
                    }
                });
            }

            let cart = {
                productId: this.detail.id,
                productStockId: stockId,
                productSku: JSON.stringify(sku),
                quantity: 1,
                pack: this.selectedPack
            };

            const response = await addToCart({cart});
            if (response.code === 0) {
                this.$parent.fetchUserInfo();

                //发送打开购物车事件
                EventBus.$emit("openPCShoppingBagEvent");
                EventBus.$emit("openH5ShoppingBagEvent");
            } else {
                this.$message({
                    showClose: true,
                    message: response.message,
                    type: "error"
                });
            }
        },
        goBack() {
            this.$router.back();
        },
        onSelectedAttribute1(item) {
            this.selectedAttribute1 = item;

            let selectedStock = null;
            if (this.selectedAttribute2) {
                this.detail.stocks.map(stock => {
                    if (this.detail.attributes[0].name in stock
                        && this.detail.attributes[1].name in stock) {
                        if (stock[this.detail.attributes[0].name] === this.selectedAttribute1.value
                            && stock[this.detail.attributes[1].name] === this.selectedAttribute2.value) {
                            selectedStock = stock;
                        }
                    }
                });
            } else {
                this.detail.stocks.map(stock => {
                    if (this.detail.attributes[0].name in stock) {
                        if (stock[this.detail.attributes[0].name] === this.selectedAttribute1.value) {
                            selectedStock = stock;
                        }
                    }
                });
            }

            this.selectedAttributePrice = selectedStock.realPrice;

            console.log('当前选择的属性：', f(selectedStock));
        },
        onSelectedAttribute2(item) {
            let selectedStock = null;
            if (this.selectedAttribute2) {
                this.detail.stocks.map(stock => {
                    if (this.detail.attributes[0].name in stock
                        && this.detail.attributes[1].name in stock) {
                        if (stock[this.detail.attributes[0].name] === this.selectedAttribute1.value
                            && stock[this.detail.attributes[1].name] === this.selectedAttribute2.value) {
                            selectedStock = stock;
                        }
                    }
                });
            } else {
                this.detail.stocks.map(stock => {
                    if (this.detail.attributes[0].name in stock) {
                        if (stock[this.detail.attributes[0].name] === this.selectedAttribute1.value) {
                            selectedStock = stock;
                        }
                    }
                });
            }

            this.selectedAttributePrice = selectedStock.realPrice;

            console.log('当前选择的属性：', f(selectedStock));
        },
        async getDetail(id) {
            const response = await fetchProductDetail(id);
            if (response.code === 0) {
                this.detail = response.data.detail;
                if (this.detail.attributes[0]) {
                    this.onSelectedAttribute1(this.detail.attributes[0].values[0]);
                }
                if (this.detail.attributes[1]) {
                    this.selectedAttribute2 = this.detail.attributes[1].values[0];
                    this.onSelectedAttribute2(this.detail.attributes[1].values[0]);
                }
            }
            console.log("产品详情：", f(this.detail));
        },
        handleScroll() {
            this.$nextTick(() => {
                let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                if (scrollTop > 380 || this.detail.images.length <= 1) {
                    this.isFixed = true;
                } else {
                    this.isFixed = false;
                }
            });
        }
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    }
};
</script>
<style lang="scss" scoped>
.packing {
    margin-bottom: 1.875rem;

    .packing-bag {
        display: flex;
        margin-top: .3rem;

        .bag-items {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 5rem;
            height: 2rem;
            border: 1px solid #d8d7d5;
            cursor: pointer;
            margin-right: .3rem;
        }

        .look-up {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3rem;
            height: 2rem;
            cursor: pointer;
        }
    }
}

.bag-items.normal {
    margin-left: .3rem;
}

.active-bag {
    background-color: #212121;
    color: #fff;
}

.product-details {
    border-top: 1px solid #d8d7d5;
}

.colorItem {
    :hover {
        cursor: pointer;
    }
}

.mobile-select-color [data-ytos-ctrl="item.selectColor"] [data-ytos-color-button] {
    display: block;
    width: 100%;
    text-align: left;
    border: 1px solid #d8d7d5;
    border-right: none;
    border-left: none;
}

ul {
    list-style: none;
}

a {
    color: inherit;
    text-decoration: none;
    font-size: inherit;
}

button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0 none;
    border-radius: 0;
    letter-spacing: inherit;
    padding: 0;
    color: inherit;

    &:hover {
        cursor: pointer;
    }
}

h1, h2, h3 {
    font-size: 1em;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.item-wrapper {
    display: flex;
    flex-flow: column;
    //flex-direction: row;
    height: auto;
    width: 100%;
    max-width: 100%;
    margin: 0 0 3.5625rem;

    .product-images-page {
        height: 100%;
    }

    .product-images-main {
        height: 100%;
    }

    .alternativeImages {
        //position: absolute;
        //top: 0;
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .back-button {
        display: none;
    }

    .PCImages {
        display: none;
    }

    .mobileSwipe {
        display: none;
    }

    .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        //line-height: 150px;
        line-height: 0;
        text-align: center;
        background-color: #39a9ed;
    }

    .product-images-thumbs-page {
        position: relative;
        align-self: flex-end;
        //left: -7.625rem;
        left: -5rem;
        top: 20rem;
        width: 4.125rem;
        display: none;

        .product-images-nav-entry {
            display: block;
            margin-top: .375rem;
            border: 1px solid transparent;
            transition: border-color .4s cubic-bezier(.22, .61, .36, 1);
        }
    }

    .product-images-nav-entry:first-child {
        margin-top: 0;
    }

    .product-images-nav-entry.product-images-entry-selected {
        border-color: #212121;
        transition: border-color .4s cubic-bezier(.55, .06, .68, .19);
    }

    .product-images-thumbs button {
        width: 100%;
        padding: .1875rem;
        display: block;
        cursor: pointer;
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .product-data-main {
        width: 100%;
    }

    .product-data {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        margin-top: .8125rem;
        position: relative;
        //height: 100%;
        height: 37rem;
        z-index: 4;

        .item-top-wrapper {
            position: relative;
        }
    }

    .desktop-select-color {
        margin-bottom: 2.625rem;
        min-height: 2.875rem;

        ul {
            display: flex;
            margin: .25rem 0 0 .125rem;
            transform: translateX(-.125rem);

            li {
                margin-right: 0.75rem;
            }

            li.selected {
                border: 1px solid #212121;
                padding: 0.125rem;
                //padding: .65rem;
            }

            .inner {
                position: relative;

                label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;

                    sapn {
                        clip: rect(1px, 1px, 1px, 1px);
                        position: absolute;
                        width: 1px;
                        height: 1px;
                        margin: -1px;
                        border: 0;
                        padding: 0;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    .selectColor {
        box-sizing: border-box;
        padding-bottom: .125rem;
        width: 100%;

        .selectionLabel {
            display: block;
            width: auto;
            pointer-events: none;
        }
    }

    .selectionLabel {
        display: block;
        width: auto;
        pointer-events: none;
    }
}

.size-guide-link-container {
    display: flex;
    font-size: .8125rem;
    justify-content: flex-end;
    width: 100%;
}

.sizeBox {
    display: flex;
    justify-content: space-between;

    span {
        color: #212121;
    }

    .soldOut {
        display: inline-block;
        color: #797768;
    }

    .unline {
        text-decoration: underline;
        cursor: pointer;
    }
}

.productSelectSize {
    button {
        background-color: #212121;
        border-width: 0;
        color: #fff;
        display: block;
        padding: 1rem 3.125rem .9375rem;
        text-align: center;
        width: 100%;
        height: 3.125rem;
        max-width: 100%;
        padding: 0;
    }

}

.share:hover {
    cursor: pointer;
    text-decoration: underline;
}


@media (min-width: 768px) {
    .site-content {
        margin-bottom: 9.375rem;
        margin-top: 6.875rem;
    }
    .item-wrapper {
        margin: 0 0 5.8125rem;

        .productImages {
            height: calc(100vh - 10rem);
            width: calc((100vh - 10rem) * .66667);
        }
    }

    .item-title {
        flex: 18.75rem 0 0;
        padding: 0;
        width: 18.75rem;

        span {
            display: block;
        }
    }
    .product-data {
        width: 40.9375rem;
        margin: 0 auto;

        .product-data-main {
            margin-top: 0;
        }
    }
    .itemPrice {
        flex: 18.75rem 0 0;
        padding: 0;
        width: 18.75rem;
    }
    .selectColor {
        flex: 18.75rem 0 0;
        padding: 0;
        width: 18.75rem;
    }
    .size-guide-link-container {
        font-size: .8125rem;
        margin-bottom: .25rem;

        button {
            padding-bottom: .125rem;
        }
    }
    .productSelectSize {
        ::v-deep .el-select {
            flex: 18.75rem 0 0;
            padding: 0;
            width: 18.75rem;
        }

        ::v-deep .el-input__inner:focus {
            border-color: #212121;
        }

        ::v-deep .el-input.is-focus .el-input__inner {
            border-color: #212121;
        }

        button {
            flex: 18.75rem 0 0;
            height: 1.875rem;
            margin: .5rem 0 0;
            max-width: 18.75rem;
            padding: 0;
            width: 18.75rem;
            margin-bottom: .6875rem;
        }
    }
    .product-data {
        width: 40.9375rem;
        margin: 0 auto;
    }
}

@media (min-width: 0) and (max-width: 1026px) {
    .site-content {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
    .product-data {
        max-height: 100%;
        height: auto;
        padding: 0 1.875rem;

        .item-title {
            max-width: 15.9375rem;

            span {
                display: block;
            }
        }
    }
    .productImages {
        margin: 0 auto;
        height: auto;
        width: calc(100% - 3.125rem * 2);
    }

}

@media (min-width: 1020px) and (max-width: 1280px) {
    .product-data {
        margin-top: 8rem !important;
        line-height: 1rem;
    }
    .share {
        position: absolute;
        top: 35.5rem;
        left: 0;
    }
    .smartphone-back-button {
        display: none;
    }
}

@media (min-width: 1024px) {
    .item-wrapper {
        flex-flow: row;
        justify-content: center;
        height: calc(100vh - 6.125rem);
        margin: 0 auto 6.0625rem;
        min-height: 44.75rem;

        .productImages {
            height: 100%;
            margin: 0;
            width: calc((100vh - 6.125rem) * .66667);
        }
    }
    .product-data {
        flex-flow: column;
        justify-content: flex-end;
        margin: 0 0 0 3.4375rem;
        width: 18.75rem;

        .product-data-main {
            margin-top: 0;
        }
    }
    .priceUpdater {
        margin-bottom: 2.375rem;
    }
    .product-data-info {
        margin-top: 1.25rem;
    }
}

@media (min-width: 0) and (max-width: 1280px) {
    .packing {
        margin-bottom: 1.875rem;
    }
    .product-text {
        padding: 1.875rem;
    }
    .product-desc {
        color: #888;
        margin-top: 1.875rem;

        ::v-deep img {
            width: 70%;
            max-height: 70%;
        }
    }
    .mobileSwipe {
        display: block !important;

        ::v-deep .van-swipe-item img {
            width: 100%;
            height: 100%;
        }

        ::v-deep .my-swipe {
            line-height: 0 !important;
            //height: 44.75rem;
        }

        ::v-deep .van-swipe__indicator {
            border: 1px solid #212121;
            background: transparent;
            opacity: 1;
        }

        ::v-deep .van-swipe__indicator--active {
            opacity: 1;
            background: #212121 !important;
        }
    }
    .item-wrapper {
        margin: 0;

        .product-data {
            height: auto;
        }

        .desktop-select-color {
            margin-bottom: 1.5rem !important;
            min-height: auto !important;
        }

        .selectColor {
            display: none;
        }

        .productSelectSize {
            display: none;
        }

        .size-guide-link-container {
            display: none;
        }

        .product-data-info {
            margin-top: 2.25rem;

            .smartphone-back-button {
                display: block;
            }
        }

        .share {
            position: absolute;
            right: 1rem;
        }

        .smartphone-back-button {
            //margin-top: 2rem;
        }
    }
    .isFixed {
        display: none;
    }
    .mobile-sticky-select-wrapper {
        display: flex;
        flex-wrap: wrap;
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 4;
        background: #fff;
        left: 0;

        //padding-bottom: env(safe-area-inset-bottom);
        .mobile-button {
            position: relative;
            background-color: #212121;
            border-width: 0;
            color: #fff;
            display: block;
            text-align: center;
            width: 100%;
            height: 3.125rem;
            max-width: 100%;
            padding: 1rem 1.1875rem 0.9375rem;
        }

        .mobile-selectionLabel {
            display: flex;
            align-items: center;
            width: 100%;
            text-align: left;
            border: 1px solid #d8d7d5;
            border-right: none;
            border-left: none;
            padding: 0 10px;
        }

        .right {
            border-left: 1px solid #d8d7d5;
        }


        .mobile-select-color {
            width: 50%;
            display: flex;
            justify-content: center;
        }

        .mobile-productSelectSize {
            width: 50%;
            position: relative;
        }
    }
    .smartphone-back-button {
        display: block;
    }
    .sticky-product-info {
        display: none;
    }
}

@media (min-width: 1280px) {
    .f {
        display: flex;
        position: relative;
    }
    .product-text {
        padding: 1.875rem;
        width: 30%;
        margin: 0 auto;
    }
    .product-desc {
        color: #888;
        margin-top: 1.875rem;

        ::v-deep img {
            width: 70%;
            max-height: 70%;
        }
    }
    .site-content {
        margin-bottom: 5.625rem;
        margin-top: 0;
        min-height: calc(100% - 6.875rem - 5rem);
        //min-height: calc(var(--inner-height) - 6.875rem);
        .item-wrapper {
            justify-content: flex-start;
            height: auto;
            margin: 0 0 8.125rem;

            .PCImages {
                display: block !important;
            }

            .productImages {
                margin: 0 0 0 calc(50% - (100vh - 13.75rem) * .33333);
                width: calc((100vh - 13.75rem) * .66667);
            }

            .product-images-page {
                display: flex;
                justify-content: center;
            }

            .product-images-main {
                order: 1;
            }

            .product-images-main-page {
                width: 100%;
                position: relative;
            }

            .product-images-main {
                //height: 100%;
            }

            .alternativeImages {
                width: 100%;

                li {
                    margin-bottom: .625rem;
                }
            }
        }

        .mobile-sticky-select-wrapper {
            display: none;
        }

        //左边
        .product-left-column {
            position: relative;
            //display: flex;
            //flex-flow: row;
            //flex-wrap: wrap;
            top: 6.875rem;
            align-self: flex-start;
            order: 0;
            height: 44.75rem;

            .back-button {
                display: block;
                white-space: nowrap;
                align-self: flex-start;
                height: 1.1875rem;
                position: relative;
                z-index: 1;
                background-color: #fff;
                text-align: right;
                top: -6.8rem;
                left: -4.5rem;
                //right: calc(100% - 24.5rem);
                //right: calc(100% + 3.4375rem);
            }

            .product-images-thumbs {
                display: block;
            }
        }

        //右边
        .product-data {
            //height: calc(100vh - 13.75rem);
            justify-content: flex-start;
            margin-left: 3.75rem;
            align-self: flex-start;
            order: 2;
            line-height: 1.1875rem;
        }
    }
    .product-data {
        height: 37rem;
    }
    .unIsFixed {
        display: none;
    }
    .isFixed {
        opacity: 1;
        display: flex;
        background: #fff;
        //width: 100%;
        padding: 0 4%;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: space-between;
        border-top: 1px solid #d8d7d5;
        height: 4rem;
        align-items: center;

        .itemPrice {
            width: auto;
        }

        .productSelectSize {
            display: flex;

            .el-select {
                margin: 0 1.875rem;

                ::v-deep input {
                    height: 32px;
                    line-height: 32px;
                    margin-top: 0.5rem;
                }
            }
        }

        .item-top-wrapper {
            display: flex;

            .item-title {
                flex: auto;
                width: auto;
                padding-right: 1.25rem;

                span {
                    display: block;
                }
            }

            .priceUpdater {
                margin-bottom: 0;
                flex: auto;
                width: auto;
                line-height: 3rem;
            }
        }

        .sticky-select-wrapper {
            display: flex;
            align-items: center;
        }

        .desktop-select-color {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            min-height: 1.875rem;

            .selectColor {
                display: flex;
                align-items: center;
                flex: auto;
                padding-bottom: 0;

                .selectionLabel {
                    padding-right: 1.25rem;
                    max-width: 8.125rem;
                    float: left;
                }
            }
        }
    }
    .product-data-info {
        //margin-top: 4.625rem;
        margin-left: 3.4375rem;
        flex: auto 0 0;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: .5rem 0;
        padding-bottom: 1.375rem;
        width: 100%;
        margin-top: 4.625rem;
    }
    .products-grid {
        margin-top: .625rem;
    }
    .smartphone-back-button {
        display: none;
    }
}

@media (min-width: 768px) and (max-height: 876px) {
    .productImages {
        width: 29.83333rem;
        height: 44.75rem;
    }

}

@media (min-width: 1280px) and (max-height: 936px) {
    .productImages {
        margin: 0 0 0 calc(50% - 14.91667rem);
        width: 29.83333rem;
    }
    .product-data {
        height: 44.75rem;
    }
}

</style>
<style scoped>
.el-select-dropdown__item.selected {
    color: black;
    font-weight: 700;
}

/deep/ .el-input__inner {
    border: 1px solid black;
    border-radius: 0;
    height: 32px;
}

/deep/ .el-input__suffix {
    display: none;
}

/deep/ .mobile-select .el-input__inner {
    color: #2c3e50;
    border: none;
}

/deep/ input::-webkit-input-placeholder {
    color: #2c3e50;
}

/deep/ input::-ms-input-placeholder {
    color: #2c3e50;
}
</style>
